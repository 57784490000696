import * as React from "react"
import { graphql, useStaticQuery} from "gatsby"
import { Section, SectionHeader, SectionContent, SectionIframe } from "@layouts/Section"
import * as Spacer from "@atoms/Spacer"
import Seo from "@components/atoms/Seo"
import Iframe from "react-iframe"
import Border from "@atoms/Border"

//ログイン後のindexページ
//ログインしてない場合は全部indexにリダイレクト
function ContactPage({ data }) {
    // const url = 'https://docs.google.com/forms/d/e/1FAIpQLSe0S4qRr2PPyL4IGZ53KNtNaFI17YHsit22B-dlHV3kjrm4BQ/viewform?embedded=true'
    // const url = 'https://docs.google.com/forms/d/e/1FAIpQLSfO8PHxMX3w1WOY8h7ZG_vUu7VygHP7u4cWyLyE80PP_J911w/viewform?embedded=true';
    const url = 'https://docs.google.com/forms/d/e/1FAIpQLSfO8PHxMX3w1WOY8h7ZG_vUu7VygHP7u4cWyLyE80PP_J911w/viewform'
    return (
        <>
            <Seo
                subtitle="Contact"
            />
            <Spacer.Header />
            <Section>
                {/* <SectionHeader
                    title="Contact"
                    text="お問い合わせ"
                /> */}
                <SectionContent>
                    <SectionIframe>
                        <Iframe src={url} width="640" height="846" frameborder="0" marginheight="0" marginwidth="0">読み込んでいます…</Iframe>
                    </SectionIframe>
                </SectionContent>
                {/* <SectionMoreLink
                    title="More"
                    text="もっとみる"
                    to="/news/"
                /> */}
            </Section>
        </>
    )
}

export default ContactPage
